.OzkeyPage {
  clear: both;
  width: 95%;
  max-width: 2000px;

  margin: 10px auto 150px;
}

@media only screen and (min-width: 600px) {
  .OzkeyPage {
    width: 90%;
  }
}
@media only screen and (min-width: 2000px) {
  .OzkeyPage {
    width: 90%;
  }
}

@media only screen and (min-width: 3000px) {
  .OzkeyPage {
    width: 90%;
  }
}
