.App-paper {
  clear: both;
  width: 95%;
  max-width: 2000px;
  padding: 50px 10px 10px 10px;
  margin: -20px auto auto;
}

@media only screen and (min-width: 600px) {
  .App-paper {
    width: 90%;
    margin: -60px auto auto;
  }
}
@media only screen and (min-width: 2000px) {
  .App-paper {
    width: 90%;
    margin: -200px auto auto;
  }
}

@media only screen and (min-width: 3000px) {
  .App-paper {
    width: 90%;
    margin: -400px auto auto;
  }
}
